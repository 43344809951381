import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/common/Forms/Inputs';
import { Box, BoxButton, BoxOptions, Formulario } from '../Login/style';
import { ContainerRegistro } from './styles';
import InputButton from '../../../components/common/Forms/FormButton';
import { Link } from 'react-router-dom';
import { NavButtonContainer } from '../Login/style';
import { Footer } from 'components/common/Footer';
import EmailForm from './EmailForm';
import { fetchWithoutToken } from 'helpers/fetch';
import { endPoints } from 'const/endPoints';
import Toast from 'components/common/Popup/Toast';
import { ClipLoader } from 'react-spinners';

const ResetPassword = () => {

    const [email, setEmail] = useState()
    const [verifiedEmail, setVerifiedEmail] = useState(false)
    const [loading, setLoading] = React.useState(false);

    const initialValues = {
        otp: '',
        email: email,
        password: '',
        confirmPassword: '',
    };


    const validationSchema = Yup.object({
        otp: Yup.string()
            .required('El otp es obligatorio')
            .min(4, 'El codigo de seguridad debe tener menos 4 caracteres')
            .max(4, 'El codigo de seguridad debe tener máximo 4 caracteres'),
        password: Yup.string()
            .min(6, 'la contraseña debe tener al menos 6 caracteres')
            .max(20, 'la contraseña debe tener máximo 20 caracteres')
            .required('La contraseña es obligatoria'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
            .required('La confirmación de la contraseña es obligatoria'),
    });

    const handleSubmit = async (values) => {
        values.otp = values.otp.trim();
        values.email = values.email.trim();
        values.password = values.password.trim();
        values.confirmPassword = values.confirmPassword.trim();
        const resp = await fetchWithoutToken(endPoints.verifyOTP, values, 'POST')
        const data = await resp.json()
        if (data.statusCode === 200) {
            Toast('success', data.message)
            window.location.href = '/access/login'
        }
        else {
            Toast('error', data.message)
        }
    };


    return (

        <>
            <>   <ContainerRegistro>
                <h1>Restablecer la contraseña</h1>
                {
                    verifiedEmail ?
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <Formulario>
                                    <Input
                                        id="otp"
                                        autoComplete="off"
                                        name="otp"
                                        type="text"
                                        placeholder="Ingrese el codigo para recuperar contraseña"
                                        heigth={'50px'}
                                        margin={'10px 0px'}
                                        errorPadding="0 0 0 15%"
                                    />
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="off"
                                        placeholder="Nueva contraseña"
                                        heigth={'50px'}
                                        margin={'10px 0px'}
                                        errorPadding="0 0 0 15%"
                                    ></Input>

                                    <Input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Confirmar nueva contraseña"
                                        heigth={'50px'}
                                        margin={'10px 0px'}
                                        errorPadding="0 0 0 15%"
                                    ></Input>
                                    <Box>
                                        <BoxButton>
                                            <InputButton text="Restablecer la contraseña" width="70%" />
                                        </BoxButton>
                                        {loading && <ClipLoader size={30} color="blue" />}
                                        <BoxOptions>
                                            <NavButtonContainer
                                                color="#797979"
                                                fontSize="1.2rem"
                                                fontWeight="300"
                                            >
                                                <Link to="/access/login">Iniciar sesion</Link>
                                            </NavButtonContainer>
                                        </BoxOptions>
                                    </Box>
                                </Formulario>
                            </Form>
                        </Formik> :
                        <EmailForm setEmail={setEmail} setVerifiedEmail={setVerifiedEmail} />
                }
            </ContainerRegistro>
            </>
        </>

    );
};

export default ResetPassword;
