export const countries = [
  { name: "Amazonas" },
  { name: "Anzoátegui" },
  { name: "Apure" },
  { name: "Aragua" },
  { name: "Barinas" },
  { name: "Bolívar" },
  { name: "Carabobo" },
  { name: "Cojedes" },
  { name: "Delta Amacuro" },
  { name: "Distrito Capital" },
  { name: "Falcón" },
  { name: "Guárico" },
  { name: "Lara" },
  { name: " Mérida" },
  { name: "Miranda" },
  { name: "Monagas" },
  { name: "Nueva Esparta" },
  { name: "Portuguesa" },
  { name: "Sucre" },
  { name: "Táchira" },
  { name: "Trujillo" },
  { name: "La Guaira" },
  { name: "Yaracuy" },
  { name: "Zulia" },
];
