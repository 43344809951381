import React from 'react';
import ReactDOM from 'react-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Stack from '@mui/material/Stack';
import { endPoints } from 'const/endPoints';
import { fetchWithToken } from 'helpers/fetch';
import Toast from "components/common/Popup/Toast";
import { ClipLoader } from 'react-spinners';

const validationSchema = yup.object({
    title: yup
        .string('Ingrese el título del video')
        .required('Ingrese el título del video'),
});

const ManualUpload = ({ lessonId, setDisplay }) => {
    const [loadingLinks, setLoadingLinks] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [initialValue, setIntialValues] = React.useState({
        title: '',
        destination: [{
            name: '240',
            url: ''
        }, {
            name: '360',
            url: ''
        }, {
            name: '480',
            url: ''
        }, {
            name: '720',
            url: ''
        }, {
            name: '1080',
            url: ''
        }
        ]
    })

    React.useEffect(async () => {
        setLoadingLinks(true)
        const videos = await fetchWithToken(`${endPoints.get_video_links_by_lesson}/${lessonId}`, {}, 'GET')
        const data = await videos.json()
        console.log(data, '')
        if (data.statusCode !== 403) {
            setIntialValues(data)
        }
        setLoadingLinks(false)
    }, [])



    const formik = useFormik({
        initialValues: {
            title: initialValue.title,
            url240: initialValue.destination[0].url,
            url360: initialValue.destination[1].url,
            url480: initialValue.destination[2].url,
            url720: initialValue.destination[3].url,
            url1080: initialValue.destination[4].url
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const resp = await fetchWithToken(`${endPoints.upload_video_by_links}/${lessonId}`, values, 'PUT')
            const data = await resp.json()
            if (data.statusCode === 200) {
                Toast('success', data.message)
            }
            else {
                Toast('error', data.message)
            }
            setDisplay(false)
            setLoading(false);
        },
    });

    return (
        <div>
            {loadingLinks && <><ClipLoader />Obteniendo enlaces antiguos Si hay alguno guardado</>}
            <form onSubmit={formik.handleSubmit}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <TextField
                        fullWidth
                        id="title"
                        name="title"
                        label="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />

                    <TextField
                        fullWidth
                        id="url240"
                        name="url240"
                        label="URL para 240px"
                        value={formik.values.url240}
                        onChange={formik.handleChange}
                        error={formik.touched.url240 && Boolean(formik.errors.url240)}
                        helperText={formik.touched.url240 && formik.errors.url240}
                    />
                    <TextField
                        fullWidth
                        id="url360"
                        name="url360"
                        label="URL para 360px"
                        value={formik.values.url360}
                        onChange={formik.handleChange}
                        error={formik.touched.url360 && Boolean(formik.errors.url360)}
                        helperText={formik.touched.url360 && formik.errors.url360}
                    />
                    <TextField
                        fullWidth
                        id="url480"
                        name="url480"
                        label="URL para 480px"
                        value={formik.values.url480}
                        onChange={formik.handleChange}
                        error={formik.touched.url480 && Boolean(formik.errors.url480)}
                        helperText={formik.touched.url480 && formik.errors.url480}
                    />
                    <TextField
                        fullWidth
                        id="url720"
                        name="url720"
                        label="URL para 720px"
                        value={formik.values.url720}
                        onChange={formik.handleChange}
                        error={formik.touched.url720 && Boolean(formik.errors.url720)}
                        helperText={formik.touched.url720 && formik.errors.url720}
                    />
                    <TextField
                        fullWidth
                        id="url1080"
                        name="url1080"
                        label="URL para 1080px"
                        value={formik.values.url1080}
                        onChange={formik.handleChange}
                        error={formik.touched.url1080 && Boolean(formik.errors.url1080)}
                        helperText={formik.touched.url1080 && formik.errors.url1080}
                    />

                    <Button color="primary" variant='text' fullWidth type="submit">
                        Submit {loading && <ClipLoader />}
                    </Button>
                </Stack>
            </form>
        </div>
    );
};
export default ManualUpload