import React from "react";

const NotFound = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <h1>404</h1>
      <h2>Page not found</h2>
    </div>
  );
};

export default NotFound;
