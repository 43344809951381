import { profileImageLink } from 'const/profileImageLink';
import { toCapitalize } from 'helpers/toCapitalize';
import { TeacherCardContainer } from './styles';
import parse from 'html-react-parser'
import Box from '@mui/material/Box';
import { Avatar, Grid, Paper } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import { fetchWithToken } from 'helpers/fetch';
import { endPoints } from 'const/endPoints';
import { Typography } from '@mui/material';
import Slide from "@mui/material/Slide";
import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import "tachyons";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Toast from 'components/common/Popup/Toast';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const TeacherCard = ({ teacher, flag, setFlag, courseId }) => {
  const user = useSelector((state) => state.auth.user);
  const { id, firstName, lastName, email, image, username, about } = teacher;
  const [openDialog, setDialog] = useState(false);
  const handleClickOpen = () => setDialog(true);
  const handleCloseDialog = () => setDialog(false);
  const name = toCapitalize(`${firstName} ${lastName}`);
  const [loading, setLoading] = useState(false);
  const [updateAbout, setUpdateAbout] = useState(about)

  const handleUpdateAbout = async () => {
    setLoading(true)
    const resp = await fetchWithToken(`${endPoints.update_teacher_about}/${id}`, { about: updateAbout }, 'PUT')
    const body = await resp.json();
    if (body.statusCode === 200) {
      Toast('success', body.message);
      setFlag(!flag)
    }
    else {
      Toast('error', body.message);
    }
    setLoading(false);
  }

  return (
    <>
      {openDialog && (<Dialog
        open={openDialog}
        BackdropProps={{
          style: { backgroundColor: "transparent" }
        }}
        fullWidth={true}
        draggable={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Actualizar descripción del maestro"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <ReactQuill scrollingContainer={'true'} theme="snow" value={updateAbout} onChange={setUpdateAbout} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleUpdateAbout()
              handleCloseDialog();
            }}
          >
            Guardar
            {loading && <ClipLoader color={"white"} size={30} />}
          </Button>
        </DialogActions>
      </Dialog>)}
      <Paper style={{ padding: 14, marginTop: 20 }}>
        <Box >
        </Box>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar alt="Remy Sharp" />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Stack direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2} >
              <h3 style={{ margin: 0, textAlign: "left" }}>{name}<br /><span style={{}}>{username}</span></h3>
              {user && user.role === 'admin' && < Stack >
                <Tooltip title={openDialog ? "" : "Actualizar descripción del maestro"}>
                  <IconButton onClick={() => {
                    handleClickOpen()
                  }} color="primary">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              }
              <Box
                sx={{
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 3
                }}
              >
              </Box>
            </Stack>
            <p style={{ textAlign: "left" }}>
              {parse(about)}
            </p>
          </Grid>
        </Grid>
      </Paper >
    </>
  );
};
