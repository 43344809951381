import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/common/Forms/Inputs';
import { Box, BoxButton, BoxOptions, Formulario } from '../Login/style';
import { ContainerRegistro } from './styles';
import InputButton from '../../../components/common/Forms/FormButton';
import { Link } from 'react-router-dom';
import { NavButtonContainer } from '../Login/style';
import { Footer } from 'components/common/Footer';
import TextField from '@mui/material/TextField';
import { endPoints } from 'const/endPoints';
import { fetchWithoutToken } from 'helpers/fetch';
import Toast from 'components/common/Popup/Toast';
import { ClipLoader } from 'react-spinners';

const EmailForm = ({ setEmail, setVerifiedEmail }) => {

    const [loading, setLoading] = React.useState(false);
    const initialValues = {
        email: ''
    }

    const validationSchema = Yup.object({
        email: Yup.string()
            .required('El email es obligatorio')
            .email('El email es inválido'),
    })

    const handleEmail = async (values) => {
        setLoading(true);
        const resp = await fetchWithoutToken(endPoints.getOTP, values, 'POST')
        const data = await resp.json()
        if (data.statusCode === 200) {
            setEmail(values.email)
            setVerifiedEmail(true)
            Toast("success", data.message)
        }
        else {
            Toast("error", data.message)
        }
        setLoading(false);
    }

    return (

        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleEmail}
            >
                <Form>
                    <Formulario>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            heigth={'50px'}
                            margin={'10px 0px'}
                            errorPadding="0 0 0 15%"
                        ></Input>
                        <Box>
                            <BoxButton>
                                <InputButton text="Obtener código de recuperar contraseña, en el correo electrónico" padding={3} width="80%" />
                            </BoxButton>
                            {loading && <ClipLoader size={30} color="blue" />}
                            <BoxOptions>
                                <NavButtonContainer
                                    color="#797979"
                                    fontSize="1.2rem"
                                    fontWeight="300"
                                >
                                    <Link to="/access/login">Iniciar sesion</Link>
                                </NavButtonContainer>
                            </BoxOptions>
                        </Box>
                    </Formulario>
                </Form>
            </Formik>
        </>
    );
};

export default EmailForm;
