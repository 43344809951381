import React from "react";
import { Container, Descripcion, TextContainer } from "../Description/styles";
import { fetchWithToken } from 'helpers/fetch';
import { endPoints } from 'const/endPoints';
import Toast from 'components/common/Popup/Toast';
import { Button, Link, Typography } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import Stack from '@mui/material/Stack';
import axios from "axios";
var FileSaver = require('file-saver');

const Certificates = ({ course }) => {
  const [body, setBody] = React.useState()
  const [loading, setLoading] = React.useState(true)
  React.useEffect(async () => {
    console.log('Loading Certificate with acios')
    axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem("at")}`,
      },
      url: `${process.env.REACT_APP_BASE_URL}${endPoints.get_certificate}/${course.id}`,
    }).then((res) => {
      console.log(res)
      if (res.status === 200) {
        Toast("success", res.data.message);
        setBody(res.data)
        setLoading(false);
      }
      setLoading(false);
    }).catch((err) => {
      Toast("error", err.response.data.message);
      setLoading(false);
      setBody(err.response.data)
      console.log("error", err);
    });

  }, []);

  const handleResource = async () => {
    FileSaver.saveAs(body.certificate.url, `${course.title} certificate`)
    Toast('success', "Resource Downloaded successfully");
  }
  return (
    <Container>
      <TextContainer>
        <Descripcion>
          {loading &&
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <ClipLoader color="blue" size={30}></ClipLoader>
              <Typography textAlign={'center'}>Espera preparando tu certificado</Typography>
            </Stack>
          }
          {
            body &&
              body.statusCode === 200 ?
              <>
                <Typography textAlign={'center'}>Boom has completado curso, descarga tu certificado</Typography>
                <Typography textAlign={'center'} variant='h4'><Button onClick={handleResource} color="primary">Haga clic aquí</Button></Typography>
              </> :
              <>
                {body && <Typography textAlign={'center'}>{body.message}</Typography>}
              </>
          }
        </Descripcion>
      </TextContainer>
    </Container>
  );
};

export default Certificates;
