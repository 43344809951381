import styled from "styled-components";

export const Container = styled.div``;

export const Modal = styled.div``;

export const Formulario = styled.div`
  width: 100%;
  padding: 20px 0 0 0px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
