import React from "react";
import { Container, Descripcion, TextContainer } from "../Description/styles";

const Teachers = () => {
  return (
    <Container>
      <TextContainer>
        <Descripcion>
          <h3>Profesores</h3>
          <p>
            - Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
            porro eos laborum. Aut consequatur neque corrupti iusto id alias ad
            atque eligendi, assumenda, ea maxime. Optio quia natus consequuntur
            nihil. - Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Qui ipsam facilis quaerat alias porro, unde ipsum dignissimos
            voluptatem excepturi perferendis cumque itaque, veritatis ad maiores
            eos, doloremque laboriosam consequuntur earum.
            <br /> <br /> <br /> - Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Soluta sit consequuntur ullam ipsa accusantium
            fugiat deserunt corrupti repellendus porro libero architecto earum,
            a iste consectetur similique? Culpa iste doloremque inventore. -
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo
            blanditiis aperiam incidunt alias similique. Optio maxime omnis
            voluptate, veritatis placeat autem? Maiores possimus ab omnis? Quia
            laudantium sequi repellat hic! Lorem ipsum dolor sit amet,
            consectetur - adipisicing elit. Ducimus nulla maiores veniam velit
            et provident dolores dolore temporibus odit nam repellat sit, nihil
            reiciendis omnis repudiandae aperiam ab nostrum labore.
          </p>
        </Descripcion>
      </TextContainer>
    </Container>
  );
};

export default Teachers;
