import React from "react";
import Pusher from "pusher-js";
import { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import { Dialog } from "primereact/dialog";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { FormContainer, UploadButton } from "pages/Profile/UploadImage/styles";
import { fetchWithTokenToUploadImage } from "helpers/fetch";
import { endPoints } from "const/endPoints";
import DoneIcon from '@mui/icons-material/Done';
import Toast from "components/common/Popup/Toast";
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ManualUpload from "./manualUpload";
const UploadLesson = ({ lessonId }) => {
  //*** Modal */
  const [display, setDisplay] = useState(false);
  const [progress, setProgress] = React.useState([]);
  const [percentage, setPercentage] = React.useState(0)
  const onClick = () => {
    setDisplay(true);
  };
  const onHide = () => {
    setDisplay(false);
  };

  //*** input para subir archivo */
  const Input = styled("input")({
    display: "none",
  });
  //**para contener el archivo */
  const [form_data, set_form_data] = useState();
  const [thereFile, setTherefile] = useState(false);
  const [manualUploading, setManualUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const send_image = (files) => {
    const formData = new FormData();
    formData.append("video", files);
    set_form_data(formData);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(form_data, 'here form_data');
    axios({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem("at")}`,
      },
      url: `${process.env.REACT_APP_BASE_URL}/lessons/upload/video/${lessonId}`,
      withCredentials: true,
      data: form_data,
      onUploadProgress: (ev) => {
        const progress = ev.loaded / ev.total * 100;
        console.log(Math.round(progress));
        setPercentage(Math.round(progress))
      },
    }).then((res) => {
      console.log(res)
      if (res.status === 200) {
        setTherefile(false);
        Toast("success", "Vídeo subido con éxito");
        set_form_data(null);
        setDisplay(false)
        setLoading(false);
        setPercentage(0)
        setProgress([])
      }
      setLoading(false);
    }).catch((err) => {
      Toast("error", err.message);
      set_form_data(null);
      setDisplay(false)
      setTherefile(false);
      setLoading(false);
      setPercentage(0)
      setProgress([])
      console.log("error", err);
    });

    // const res = await fetchWithTokenToUploadImage(
    //   `${endPoints.upload_video}/${lessonId}`,
    //   form_data,
    //   "PUT"
    // );
    // const body = await res.json();
  };

  useEffect(() => {
    var pusher = new Pusher('d19c7d239479b97f042e', {
      cluster: 'ap1'
    });
    var channel = pusher.subscribe('my-channel');
    channel.bind('video', function (data) {
      //console.log("progress", data.message);
      const newArray = [...progress, data.message]
      setProgress(newArray)
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [progress])

  return (
    <>
      <button
        onClick={() => onClick()}
        style={{
          margin: "0px",
          padding: "0px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <FileUploadIcon color="primary" />
      </button>

      <Dialog
        header="Subir vide de la clase"
        visible={display}
        contentStyle={{ borderRadius: " 0 0 10px 10px", overflow: "auto" }}
        style={{ width: "65vw" }}
        onHide={() => onHide()}
        resizable={true}
        draggable={true}
      >
        <Stack direction="row"
          justifyContent="center"
          alignItems="center" >
          <Button onClick={() => setManualUploading(!manualUploading)}>
            {!manualUploading ? <h5>Subir vídeo directamente</h5> : <h5>Subir video por enlaces</h5>}
          </Button>
        </Stack>
        {
          manualUploading ? <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormContainer onSubmit={handleSubmit}>
                <label htmlFor="icon-button-file">
                  <Input
                    accept="video/*"
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => {
                      send_image(e.target.files[0]);
                      setTherefile(true);
                    }}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload video  "
                    component="span"
                    sx={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#757575",
                      marginBottom: "0.6rem",
                    }}
                  >
                    <VideoLibraryIcon />
                  </IconButton>
                  {loading && <ClipLoader color={"black"} size={30} />}
                  <UploadButton>
                    {!thereFile ? (
                      <Button
                        sx={{
                          color: "#707070 !important",
                          backgroundColor: "#cbcbcb !important",
                        }}
                        disabled
                      >
                        Subir video
                      </Button>
                    ) : (
                      <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? "loading..." : "Subir video de la clase"}
                      </Button>
                    )}
                  </UploadButton>
                </label>
              </FormContainer>
            </Box>
            <Stack marginTop={10}>
              <LinearProgress color="success" variant="determinate" value={percentage} />
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: false,
                  maxHeight: 300,
                  '& ul': { padding: 0 },
                }}
              >
                {progress.map((item) => (
                  <ListItem key={`item-${item}`}>
                    <ListItemText primary={item} /><DoneIcon color="blue" />
                  </ListItem>
                ))}
              </List>
            </Stack></> :
            <>
              <ManualUpload lessonId={lessonId} setDisplay={setDisplay} />
            </>
        }

      </Dialog>
    </>
  );
};

export default UploadLesson;
