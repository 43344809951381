export const colors = {
  white: '#ffffff',
  black: '#25283b',
  red: '#ff555b',
  gray: '#b8b8b8',
  darkGray: '#767676',
  lightGray: '#C1C1C1',
  headerBlue: '#5e82be',
  navBlue: '#5e82be',
  navBlueHover: '#a3bee9',
  textBlue: '#343f79',
  titleBlue: '#5373b2',
  transparent: 'transparent',
  lightBlue: '#3daecc',
  lightBlueBackground: '#f7fbfe',
  lightGrayBackground: '#f8f8f8',
};
